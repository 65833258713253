<template>
  <div class="gallery-container h-dvh bg-[#121212]">
    <div class="gallery-grid">
      <div
        v-for="card in cards"
        :key="card.id"
        class="gallery-item"
        @click="showCardDetail(card)"
      >
        <img :src="card.art" :alt="card.name" class="thumbnail" />
        <div class="overlay">
          <span class="card-name">{{ card.name }}</span>
        </div>
      </div>
    </div>

    <!-- Modal for Card Detail View -->
    <div v-if="selectedCard" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <img :src="selectedCard.art" alt="Full Art" class="full-size-img" />
        <div class="card-info">
          <h2>{{ selectedCard.name }}</h2>
          <p><strong>Type:</strong> {{ selectedCard.type }}</p>
          <p><strong>Cost:</strong> {{ selectedCard.cost }}</p>
          <p><strong>Abilities:</strong> {{ selectedCard.abilities }}</p>
          <p>
            <strong>Power/Toughness:</strong> {{ selectedCard.power }}/{{
              selectedCard.toughness
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance

export default {
  name: "Gallery",
  data() {
    return {
      cards: [], // Store all card data
      selectedCard: null, // The card selected for full view
    };
  },
  methods: {
    async fetchCards() {
      try {
        // Fetch all card documents from Firestore
        const querySnapshot = await getDocs(collection(db, "cards"));
        this.cards = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    showCardDetail(card) {
      this.selectedCard = card;
    },
    closeModal() {
      this.selectedCard = null;
    },
  },
  created() {
    this.fetchCards(); // Fetch cards when component is mounted
  },
};
</script>

<style scoped>
.gallery-container {
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  border: 1px solid black;
}

.thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.card-name {
  padding: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.full-size-img {
  max-width: 400px;
  max-height: 600px;
}

.card-info {
  max-width: 300px;
}
</style>
