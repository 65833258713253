<template>
  <nav>
    <div
      class="flex gap-8 py-2 px-4 items-center bg-[var(--magic-grey)] font-jacebeleren text-xl text-[var(--magic-white)]"
    >
      <div>
        <img src="../assets/main-logo.webp" height="60" width="60" />
      </div>
      <router-link to="/">Home</router-link>

      <router-link to="/gallery">Gallery</router-link>
    </div>
  </nav>
</template>
<script setup></script>
<style scoped></style>
