<template>
  <div class="flex flex-col items-center justify-center bg-[#121212] h-dvh">
    <h1 class="text-4xl lg:text-6xl font-belerenbold text-gray-200 mb-4">
      Magic The Generator
    </h1>
    <h2 class="text-base text-gray-200 mb-8">An AI MTG Card generator</h2>
    <form
      class="input-container flex items-center"
      @submit.prevent="generateCard"
    >
      <input
        v-model="prompt"
        placeholder="Enter your card prompt"
        autocomplete="off"
      />
      <button class="button font-jacebeleren" :disabled="loading">
        create
      </button>
    </form>

    <div v-if="loading" class="spinner-container">
      <LoadingSpinner />
    </div>

    <div class="mt-2">
      <CardComponent
        v-if="cardTextLoaded && cardArtLoaded"
        :cardTitle="cardProperties.name"
        :cardCost="cardProperties.cost"
        :cardType="cardProperties.type"
        :cardDescription="cardProperties.abilities"
        :cardPower="cardProperties.power"
        :cardToughness="cardProperties.toughness"
        :cardArt="cardProperties.art"
      />
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "Home",
  components: { CardComponent, LoadingSpinner },
  data() {
    return {
      loading: false,
      cardTextLoaded: false,
      cardArtLoaded: false,
      prompt: "",
      cardProperties: {
        name: "",
        cost: "",
        type: "",
        power: "",
        toughness: "",
        abilities: "",
        art: "",
      },
    };
  },
  methods: {
    async generateCard() {
      this.loading = true;
      this.cardTextLoaded = false;
      this.cardArtLoaded = false;

      try {
        const response = await fetch(
          "https://us-central1-mtgen-host.cloudfunctions.net/generateCardWithArt",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ prompt: this.prompt }),
          }
        );

        if (response.ok) {
          const cardData = await response.json();

          // Assign the received card properties from the response
          this.cardProperties = {
            name: cardData.name,
            cost: cardData.cost,
            type: cardData.type,
            power: cardData.power,
            toughness: cardData.toughness,
            abilities: cardData.abilities,
            art: cardData.art, // Using the art URL directly
          };

          // Set loaded flags to display the card
          this.cardTextLoaded = true;
          this.cardArtLoaded = true;
        } else {
          console.error("Failed to generate card:", await response.text());
        }
      } catch (error) {
        console.error("Error generating card:", error);
      } finally {
        this.loading = false;
        if (!this.cardTextLoaded || !this.cardArtLoaded) {
          alert("Failed to load all card components. Please try again.");
        }
      }
    },
  },
};
</script>

<style>
.card {
  width: 600px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin-top: 1rem;
  width: 70px;
  height: 70px;
}

.input-container {
  display: flex;
  background: white;
  border-radius: 1rem;
  background: linear-gradient(135deg, #23272f 0%, #14161a 100%);
  padding: 0.3rem;
  gap: 0.3rem;
}

.input-container input {
  border-radius: 0.8rem 0 0 0.8rem;
  background: #23272f;
  width: 100%;
  flex-basis: 75%;
  padding: 1rem;
  border: none;
  color: white;
  transition: all 0.2s ease-in-out;
}

.input-container button {
  flex-basis: 25%;
  padding: 1rem;
  background: linear-gradient(
    135deg,
    rgb(243, 141, 51) 0%,
    rgb(255, 104, 3) 100%
  );
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--magic-black);
  border: none;
  width: 100%;
  border-radius: 0 1rem 1rem 0;
  transition: all 0.2s ease-in-out;
}

.input-container button:hover {
  background: linear-gradient(
    135deg,
    rgb(255, 170, 96) 0%,
    rgb(255, 119, 29) 100%
  );
}
</style>
