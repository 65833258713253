// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQtP6vli1DhpOufJv0uUm3fQlqiQio71Q",
  authDomain: "mtgen-host.firebaseapp.com",
  projectId: "mtgen-host",
  storageBucket: "mtgen-host.appspot.com",
  messagingSenderId: "307022547034",
  appId: "1:307022547034:web:e83e26c0557cf951ddb063",
  measurementId: "G-4D4JQQ6ZEW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
