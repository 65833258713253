import { createRouter, createWebHashHistory } from "vue-router";
import LandingView from "@/views/Home.vue";
import Gallery from "@/views/Gallery.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
